// Global Variables

@import "global/global-wilo-variables";
@import "general/wilo-variables";

// Global Mixins
@import "global/global-wilo-mixins";

// $headings-line-height: 1.5;
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootstrap-sass";
@import "global/global-wilo-fonts";

// Plugins
@import "~hamburgers";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
@import "magnific-popup/src/css/main";
@import "animate-sass/_animate"; // https://github.com/tgdev/animate-sass
@import "~select2/src/scss/core"; // Select2
@import "~select2/src/scss/theme/default/layout"; // Select2

@import "~@glidejs/glide/dist/css/glide.core";

//Spacing
@import "general/spacing/margin";
@import "general/spacing/padding";

// App CSS
@import "general/general";
@import "general/font";
@import "sections/glossary";
@import "global/global-wilo-demo-nav";
@import "general/select2";
@import "general/flatpickr";
@import "sections/forms";
@import "sections/magazine_index";
@import "general/uniform";
@import "general/tables";
@import "general/lightboxes";
@import "general/wilo-color-theme";
@import "general/text-color";
//@import "bt-extension";
@import "pageparts/_navigation";
@import "pageparts/mega-menue";
@import "pageparts/slider_header";
@import "pageparts/parallax-header";
@import "sections/product-slider";
@import "sections/toolbar";
@import "sections/news-teaser";
@import "sections/newsletter-box";
@import "sections/events";
@import "sections/my-wilo";
@import "sections/world-explorer";
@import "sections/survey";
@import "sections/downloads";
@import "global/global-wilo-footer";
@import "general/media-queries";
@import "sections/index-page";
@import "sections/news";
@import "sections/blog";
@import "sections/product-catalog";
@import "sections/pumpfinder";
@import "sections/st_product_detail";
@import "pageparts/content-boxes";
@import "sections/rt_product_margin_teaser";
@import "sections/rt_flyout";
@import "sections/series_families";
@import "sections/st_myWilo_profile";
@import "sections/replacementguide";
@import "pageparts/cookies";
@import "sections/st_wishlist";
@import "sections/404";
@import "sections/st_login-page";
@import "pageparts/alert-off-canvas";
@import "pageparts/offcanvas";
@import "sections/chat";
@import "sections/order_and_downloads";
@import "sections/contentSlider";
@import "sections/three_column_teaser/threeColumnTeaser";
@import "sections/three_column_gallery/threeColumnGallery";
@import "sections/st_page_bottom_teaser";
@import "general/shared/tile";
@import "sections/teaser/st_news_teaser";

// import marginal modules
@import "sections/marginal_modules/marginal_general";
@import "sections/marginal_modules/st_marginal_contact_module";
@import "sections/marginal_modules/st_marginal_downloads_module";
@import "sections/marginal_modules/st_marginal_text_list_module";

// import subpage modules
@import "sections/sub_page_modules/sub_page_modules";
@import "sections/sub_page_modules/st_image_module";
@import "sections/sub_page_modules/st_text_module";
@import "sections/sub_page_modules/st_video_module";
@import "sections/sub_page_modules/st_table_module";
@import "sections/sub_page_modules/st_download_tile_module";
@import "sections/sub_page_modules/st_tabs_module";
@import "sections/sub_page_modules/st_floating_text_image_paragraph";
@import "sections/sub_page_modules/st_link_list";
@import "sections/sub_page_modules/st_dot_list";
@import "sections/sub_page_modules/st_survey";
@import "sections/sub_page_modules/st_pump_finder_teaser_module";
@import "sections/sub_page_modules/st_pump_replacement_teaser_module";
@import "sections/sub_page_modules/st_wilo_webshop_teaser";
@import "sections/sub_page_modules/st_add-to-cart-box_module";
@import "sections/sub_page_modules/st_join_myWilo_box";
@import "sections/sub_page_modules/st_assistant_app_module.scss";
@import "sections/sub_page_modules/st_assistant_app_module.scss";
@import "sections/sub_page_modules/st_ecolution_text_video";
@import "sections/sub_page_modules/st_ecolution_award_module.scss";
@import "sections/sub_page_modules/st_ecolution_accordion";



// import pages
@import "pages/pt_cart-checkout";
@import "pages/_pt_country_selection";
@import "pages/pt_cart-overview";
@import "pages/pt_webshop-series-overview";
@import "pages/_pt_order_confirmation";
@import "pages/pt_ecolution";
@import "pages/pt_bsr_content_page";
@import "sections/sub_page_modules/st_countdown";
@import "sections/sub_page_modules/st_iframe";
@import "sections/sub_page_modules/st_breadcrumb";
@import "sections/sub_page_modules/st_tipps_and_guides";
@import "sections/sub_page_modules/st_wilo_services";
@import "sections/sub_page_modules/st_wilo_config_box";
@import "sections/sub_page_modules/st_higlight_headline";
@import "sections/sub_page_modules/st_new_slider";
@import "sections/sub_page_modules/st_hero_teaser";
@import "sections/sub_page_modules/st_highlight_teaser_topic";
@import "sections/sub_page_modules/st_ecolution_lead";
@import "sections/sub_page_modules/st_ecolution_two_column_teaser";
@import "sections/sub_page_modules/st_text_on_image";
@import "sections/sub_page_modules/st_ecolution_countdown";
@import "sections/sub_page_modules/st_ecolution_full_screen_video";
@import "sections/sub_page_modules/st_ecolution_tripple_text_image";
@import "sections/sub_page_modules/st_ecolution_thank_you";
@import "sections/bsr_modules/st_bsr_stage";
@import "sections/bsr_modules/st_bsr_three_column_teaser";
@import "sections/bsr_modules/st_bsr_icon_teaser";
@import "sections/bsr_modules/st_bsr_teaser";
@import "sections/bsr_modules/st_bsr_video_teaser";
@import "sections/bsr_modules/st_bsr_product_slider";
@import "sections/bsr_modules/st_bsr_installer_search";
@import "sections/bsr_modules/st_bsr_blog_teaser_slider";
@import "sections/bsr_modules/st_bsr_product_use_case_table";
@import "sections/bsr_modules/st_bsr_pump_replacement_teaser";
@import "sections/bsr_modules/st_bsr_fault_signal_assistance";
@import "sections/bsr_modules/st_bsr_anchor_navigation";
@import "sections/bsr_modules/st_bsr_introduction";
@import "sections/bsr_modules/st_bsr_sub_navigation";


// import productseries styles
@import "pageparts/alternate-navigation";
@import "sections/productseries";
@import "sections/product-results";

// Arithnea Productseries imports
@import "sections/productlandingpage-sections";

@import "sections/download_page";
@import "sections/faq_page";
@import "sections/references";
@import "sections/tools_page";
@import "sections/locator_page";
@import "sections/form_page";

// Target Group Specific
@import "general/target-group";
@import "general/fix";

// Search
@import "sections/searchresults";

// RTT
@import "sections/rtt_styles";

@import "sections/badge";

.icon-circle-cross {
  float: right;
  margin: 5px;
}
